<template>
  <div id="round-overall-report" class="pb-5 position-relative">
    <div class="page-header mb-1">
      <h4>ดูของรวม</h4>
    </div>
    <b-card no-body class="mb-1">
      <b-card-header header-tag="nav">
        <div class="d-flex justify-content-between">
          <h5 class="card-title">
            <i class="fas fa-info-circle text-danger mr-2"></i>
            <span class="text-dark mr-2">เฉพาะ</span>
            <span class="text-primary mr-2">{{ roundName }}</span>
            <span class="text-dark mr-2">วันที่</span>
            <span class="text-primary mr-2">{{ roundDate }}</span> เท่านั้น
            <small class="text-secondary">(เปลี่ยนได้ที่แถบด้านบน)</small>
          </h5>
          <span>
            <button type="button" class="btn btn-warning border-warning btn-sm py-0 mr-2" @click="getReport(false)">รีเฟรช</button>
            <small>(รีเฟรชใน {{countDown}} วินาที)</small>
          </span>
        </div>

        <b-nav card-header tabs>
          <b-nav-item @click="activeTab='OverallTop'" :active="activeTab==='OverallTop'">เลขบน</b-nav-item>
          <b-nav-item @click="activeTab='OverallBottom'" :active="activeTab==='OverallBottom'">เลขล่าง</b-nav-item>
        </b-nav>
      </b-card-header>

      <RoundOverallTop
        v-if="activeTab==='OverallTop'"
        :isLoading="isLoading"
        :data="numberItems"
      />

      <RoundOverallBottom
        v-if="activeTab==='OverallBottom'"
        :isLoading="isLoading"
        :data="numberItems"
      />
    </b-card>

    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      background-color="#EEEEEE"
      :height="80"
      :width="80"
      color="#E5881E"
      loader="dots"
    />
  </div>
</template>
<script>
import ReportService from "@/services/ReportService"
import moment from '@/helpers/moment'
import cAlert from '@/helpers/alert'

import RoundOverallTop from './components/RoundOverallTop'
import RoundOverallBottom from './components/RoundOverallBottom'

export default {
  name: 'RoundOverall',
  components: {
    RoundOverallTop,
    RoundOverallBottom
  },
  data() {
    return {
      data: null,
      isLoading: false,
      activeTab: 'OverallTop',
      refreshInterval: null,
      countDown: 299
    }
  },
  computed: {
    roundId() {
      return this.$store.state.globalMarket?.round?.roundId
    },
    roundName() {
      if(!this.data)
        return ''

      // return `[${this.data.round.note.groupTitle}] ${this.data.round.note.marketTitle}`
      return `หวย${this.data.round.note.marketTitle}`
    },
    roundDate() {
      if(!this.data)
        return ''

      return moment(this.data.round.roundDate.date).format("DD/MM/YYYY")
    },
    dataItems() {
      return this.data?.items || []
    },
    numberItems() {
      return this.dataItems.reduce((items, item)=>{
        items[item._id.type][item._id.number] = {
          length: item.count,
          amount: item.amount,
          received: item.agentAmount
        }
        return items
      }, {
        threeNumberTop: {},
        threeNumberTode: {},
        twoNumberTop: {},
        twoNumberBottom: {},
        runTop: {},
        runBottom: {}
      })
    },
  },
  watch: {
    roundId(n, o) {
      if(n)
        this.getReport()
    }
  },
  methods: {
    getReport(silent=false) {

      /**
       * ตรวจสอบประเภทหวย
       */
      if(this.$store.state.globalMarket?.marketType==='lottoset') {
        return this.$router.push({name: 'SetsOverall'})
      }

      if(!silent)
        this.isLoading = true

      clearInterval(this.refreshInterval)
      ReportService.getRoundOverall(this.roundId, silent)
      .then((response)=>{
        if(response.success) {
          this.data = response.data
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: e?.message || 'โหลดข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
        this.countDownRefresh()
      })
    },
    countDownRefresh() {
      this.countDown = 299
      clearInterval(this.refreshInterval)
      this.refreshInterval = setInterval(()=>{
        this.countDown--
        if(this.countDown < 1) {
          this.getReport(true)
        }
      }, 1000)
    }
  },
  mounted() {
    this.getReport()
  },
  beforeDestroy() {
    clearInterval(this.refreshInterval)
  }
}
</script>
<style lang="scss" scoped>
#round-overall-report {
  .table {
    thead {
      tr {
        th {
          text-align: center;
        }
        td {
          text-align: center;
          font-size: 85%;
        }
      }
    }
  }
}
</style>
